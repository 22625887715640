export const environment = {
  production: true,
  shouldInitSentry: true,
  applicationUrl: 'https://spaceshout.com/',
  cloudinaryName: 'spaceshout',
  apiKey: '361628317534762',
  firebase: {
    apiKey: 'AIzaSyA3EqmivderfeY_o4Zb_5mB6kuzuYvFZLI',
    authDomain: 'spaceshout-prod.firebaseapp.com',
    projectId: 'spaceshout-prod',
    storageBucket: 'spaceshout-prod.appspot.com',
    messagingSenderId: '1076126403001',
    appId: '1:1076126403001:web:e14acc641ec3f86d474e68',
    measurementId: 'G-LR08FG7QJG',
  }
};
