import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth/auth.service';
import { RegistrationService } from '../../shared/services/registration/registration.service';
import { Log } from 'ng2-logger/browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService } from 'app_code/app/shared/services/toast/toast.service';
import { ToastMessageType } from 'app_code/app/shared/enums/toast-message-type';

@Component({
  selector: 'shout-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit, OnDestroy {

  private log = Log.create('ActivateAccountComponent');
  private subscriptions: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,
              private registrationService: RegistrationService,
              private toastService: ToastService,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit() {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        let accountActivationId = params["account"];
        let emailActivationId = params["email"];
        this.log.data("accountActivationId: " + accountActivationId);
        if(accountActivationId) {
          this.subscriptions.add(
            this.registrationService.activateAccount(accountActivationId)
            .subscribe(
              (data) => {
                this.log.data("Account activation successful");
                this.toastService.open(ToastMessageType.success, 'activate_account.valid_link');

                this.authService.logout();
              },
              (error) => {
                this.log.data("Error: " + error);
                this.toastService.open(ToastMessageType.info, 'activate_account.invalid_link');

                this.router.navigate([""], {})
              }
            )
          );
        } else if (emailActivationId) {
          this.subscriptions.add(
            this.registrationService.activateEmail(emailActivationId)
            .subscribe(
              (data) => {
                this.log.data("Email activation successful");
                this.toastService.open(ToastMessageType.success, 'activate_account.valid_link_email');

                this.authService.logout();
              },
              (error) => {
                this.log.data("Error: " + error);
                this.toastService.open(ToastMessageType.info, '"activate_account.invalid_link');

                this.router.navigate([""], {})
              }
            )
          );
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
