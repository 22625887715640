import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { WindowResizeService } from './shared/services/window-resize.service';
import { UserUpdateService } from './shared/services/user/userUpdate.service.';
import { HashtagService } from './shared/services/hashtag/hashtag.service';
import { NavigateByHashtagDirective } from "./shared/directives/navigateByHashtag.directive";
import { TagPipe } from './shared/pipes/tag.pipe';
import { UrlPipe } from './shared/pipes/url.pipe';
import { EmailPipe } from './shared/pipes/email.pipe';
import { AuthService } from './shared/services/auth/auth.service';
import { LoginService } from './ui/modals/services/login/login.service';
import { RegistrationService } from './shared/services/registration/registration.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ActivateAccountComponent } from './ui/activate-account/activate-account.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { AdminGuard } from './routing/route-guards/admin-guard.service';
import { AuthGuard } from './routing/route-guards/auth-guard.service';
import { PointService } from './shared/services/point/point.service';
import { ReplyService } from './shared/services/reply/reply.service';
import { ReportService } from './shared/services/report/report.service';
import { MapService } from './ui/map/services/map.service';
import { UserService } from './shared/services/user/user.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadModule } from 'ng2-file-upload';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { JwtModule } from '@auth0/angular-jwt';
import { UrlManagerService } from './shared/services/url-manager.service';
import { AuthInterceptor } from './shared/services/auth/auth.interceptor';
import { SearchMenuService } from "./ui/search-panel/services/search-menu.service";
import { RxStompService } from '@stomp/ng2-stompjs';
import { ChatService } from './shared/services/chat/chat.service';
import { FeedbackService } from './shared/services/feedback/feedback.service';
import { MixpanelService } from './shared/services/login/mixpanel.service';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { SubscribedAreaService } from './shared/services/subscribed-area/subscribed-area.service';
import { InitializationService } from './shared/services/login/initialization.service';
import { CollectionService } from './shared/services/point/collection.service';
import { AddressSelectService } from './shared/services/point/address-select.service';
import { SharedModule } from './shared/shared.module';
import { PointOnMapMarkerComponent } from './ui/map/components/point-on-map-marker/point-on-map-marker.component';
import { PointOnMapMarkerService } from './ui/map/services/point-on-map-marker.service';
import { DOMAIN } from './shared/constants';
import { NotificationService } from './ui/notifications/services/notification.service';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { JwtToken } from './shared/interfaces/jwt-token';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { PointWithImageComponent } from './ui/map/components/point-with-image/point-with-image.component';
import { TextPointComponent } from './ui/map/components/text-point/text-point.component';

export function tokenGetter() {
  let token = localStorage.getItem("id_token");
  return token ? (JSON.parse(token) as JwtToken).token : null;
}

export function initApp(initializationService: InitializationService): () => Promise<any> {
  return () => initializationService.initialize();
}

export function appInitializerFactory(translateService: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    translateService.setDefaultLang('en-US');
    let code: string = translateService.getBrowserLang();
    try {
      switch (code) {
        case "pl":
          await translateService.use(code).toPromise();
          break;
        default:
          await translateService.use("en-US").toPromise();
          break;
      }
    } catch (err) {
       
    }
  }
}

@NgModule({ declarations: [
        AppComponent,
        ActivateAccountComponent,
        TagPipe,
        UrlPipe,
        EmailPipe,
        NavigateByHashtagDirective,
        PointOnMapMarkerComponent,
        PointWithImageComponent,
        TextPointComponent
    ],
    bootstrap: [
        AppComponent,
    ], imports: [LottieComponent,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        FileUploadModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ["localhost:4200", "localhost:8080", "spaceshout.com"]
            }
        }),
        InfiniteScrollModule,
        SocialLoginModule,
        BrowserAnimationsModule,
        SharedModule], providers: [
        AddressSelectService,
        AdminGuard,
        AuthGuard,
        AuthService,
        CollectionService,
        PointService,
        ReplyService,
        ReportService,
        LoginService,
        MapService,
        PointOnMapMarkerService,
        MixpanelService,
        RegistrationService,
        UserService,
        UserUpdateService,
        HashtagService,
        SearchMenuService,
        SubscribedAreaService,
        ChatService,
        UrlManagerService,
        WindowResizeService,
        CookieService,
        FeedbackService,
        NotificationService,
        InitializationService,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [InitializationService, HttpClient],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector, Sentry.TraceService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                lang: 'en',
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('914378287965-g27mahudpsv6rrkabk135gkj9ibklucf.apps.googleusercontent.com', {
                            scopes: 'openid profile email',
                        }),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('381698495626679', {
                            scope: 'email, user_friends, public_profile',
                            return_scopes: true,
                            enable_profile_selector: true,
                            version: "v2.11"
                        })
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        },
        provideLottieOptions({
            player: () => import('lottie-web/build/player/lottie_light'),
        }),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        RxStompService,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
    ] })
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?v=" + Date.now());
}